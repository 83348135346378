label {
  @include font($fontB);
  text-transform: uppercase;
  font-size: 16px;

  &[for] {
    cursor: pointer;
  }
}

.input {
  &-group {
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &-addon {
      padding: rem(6px) rem(12px);
      & > select {
        border-radius: 0;
        height: rem(42px);
        border: 0 none transparent;
        padding: rem(6px) rem(12px);
        margin: rem(-8px) rem(-12px);
      }
    }
  }
  .inputs-list {
    padding-left: 0;
    margin-bottom: 0;

    .hs-form-radio,
    .hs-form-booleancheckbox {
      .hs-input {
        width: 16px;
        height: 16px;
        display: flex;
        appearance: none;
        position: relative;
        align-items: center;
        justify-content: center;
        &:before {
          opacity: 0;
          content: "";
          width: 100%;
          height: 100%;
          font-size: 14px;
          line-height: 16px;
          border-radius: 50%;
          text-align: center;
          position: absolute;
          @extend %animateFast;
          font-family: $icomoon-font-family;
        }
        &:checked {
          color: map-get($color, dark);
          border-color: map-get($color, dark);
          &:before {
            opacity: 1;
          }
        }
      }
    }

    .hs-form-radio {
      .hs-input {
        &:checked {
          &:before {
            content: "";
            transform: scale(0.6);
            background: map-get($color, dark);
          }
        }
      }
      span {
        @include media-breakpoint-down(md) {
          //  position: absolute;
          // display: inline-block;
          // padding-right: 20px;
        }
      }
    }

    .hs-form-booleancheckbox {
      @include notaList;
      padding-top: 30px;
      .hs-form-booleancheckbox-display {
        .hs-input {
          padding: 0;
          width: 18px;
          height: 18px;
          margin-top: 6px;
          border: 1px solid map-get($theme, dark);
          border-radius: 2px;
          // &.invalid {
          //   &.error {
          //     .hs-form-booleancheckbox .hs-form-booleancheckbox-display input {
          //       border-color: map-get($color, danger);
          //     }
          //   }
          // }
        }
        span {
          color: map-get($theme, textL);
          p {
            display: flex;
            font-size: 18px;
            line-height: 135%;
            padding-left: 13px;
            text-transform: none;
            @include font($font);
            color: map-get($theme, text);
            text-align: left;
            &:nth-child(3) {
              transform: translateX(-35px);
              width: calc(100% + 35px);
            }
          }
        }
      }

      .hs-input {
        &:checked {
          &:before {
            content: $i-check;
          }
        }
      }
    }
    .hs-form-radio {
      @include notaList;

      padding-bottom: 25px;
      @include media-breakpoint-down(sm) {
        padding-bottom: 16px;
        position: relative;
      }
      &:last-child {
        padding-bottom: 0;
      }
      // &:first-child {
      //   @include media-breakpoint-down(sm) {
      //     padding-bottom: 30px;
      //   }
      // }
      .hs-input {
        padding: 0;
        margin-top: 3px;
        margin-right: 14px;
        border-radius: 50%;
        border: 1px solid map-get($theme, text);
        display: inline-block;
        @include media-breakpoint-down(sm) {
          position: absolute;
          left: 0;
        }
      }

      & > label {
        display: block;
        text-transform: none;
        @include font($font);
        font-size: rem(22px);
        line-height: normal;
        padding-left: 15px;
        @include media-breakpoint-down(md) {
          font-size: rem(20px);
          line-height: normal;
          padding-left: 33px;
        }
      }
    }

    &.invalid {
      &.error {
        .hs-form-radio,
        .hs-form-booleancheckbox {
          .hs-input {
            border-color: map-get($color, danger);
          }
        }
      }
    }
  }

  .hs_submit {
    font-size: 22px;
    max-width: 137px;
    font-weight: bold;
    line-height: 22px;
    --bs-border-radius: 10em;
    text-transform: uppercase;
    color: map-get($theme, text);
    background: map-get($theme, bg);
    border-color: map-get($theme, text);
    border-radius: var(--bs-border-radius) !important;
  }
}
// .hs-error-msgs {
//   list-style: none;
//   margin-bottom: 0;
//   text-transform: capitalize;
//   @include notaList;
//   padding-left: 0;
//   @include font($font);
//   color: map-get($color, danger);
//   .hs-error-msgs {
//     text-transform: capitalize !important;
//   }
// }
.form {
  .hs-richtext {
    font-size: 10px;
    &.hs-main-font-element {
      h3 {
        font-size: 26px;
        margin-bottom: 30px;
        @include media-breakpoint-down(md) {
          font-size: 24px;
          margin-bottom: 25px;
          text-align: left !important;
        }
      }
    }
  }
  .fillForm {
    font-size: 26px;
    padding-bottom: 30px;
    margin-bottom: 0;
    @include font($fontB);
  }
  .submitted-message {
    p {
      &:first-child {
        font-size: 26px;
        @include font($fontB);
      }
      &:last-child {
        font-size: 22px;
        @include font($font);
        line-height: 150%;
      }
    }
  }
  &-group {
    margin-bottom: rem(8px);
    .form-text {
      font-size: 12px;
      line-height: 1em;
      &.form-error {
        color: map-get($color, danger);
      }
    }
    &:not(.notValid) {
      // margin-bottom: rem(25px);
      .form-text.form-error {
        opacity: 0;
      }
    }
    &.notValid {
      .form-control,
      .SumoSelect .CaptionCont {
        border-color: map-get($color, danger);
      }

      .form-text.form-error {
        opacity: 1;
      }
    }
  }
  &-control {
    height: 38px;
    font-size: 16px;
    border-radius: 0;
    @include font($fontL);
    padding: rem(5px) rem(15px);
    color: map-get($theme, text);
    border: 1px solid map-get($theme, brdr);
    // @include placeholder {
    //   color: #8f8e8e;
    // }
    &-static {
      @include font($fontB);
    }
    &:focus-visible {
      text-shadow: none;
    }
  }
  .SumoSelect {
    height: 50px;
    font-size: 18px;
    border-radius: 2px;

    &.open {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
    & > .optWrapper,
    & > .CaptionCont {
      // box-shadow: none;
      border-color: transparent;
      background: map-get($theme, gray);
      // border-left: 10px solid map-get($theme, color);
      & > .options li.opt {
        padding: 4px 20px;
      }
    }
    & > .CaptionCont {
      padding: 4px 4px 4px 20px;
      & > label {
        width: 30px;
        & > i {
          font-size: 30px;
        }
      }
    }
    &.accent,
    &.required {
      & > .optWrapper,
      & > .CaptionCont {
        border-left-color: map-get($theme, accent);
      }
    }
    & > .CaptionCont {
      &,
      & > span {
        min-height: 42px;
        line-height: 42px;
      }
    }
    & > .optWrapper {
      border-radius: 0;
      top: calc(100% - 1px);
    }
  }
  .hs-form-required {
    display: none;
  }
  input:not([type="radio"]):not([type="checkbox"]) {
    // border: 1px solid map-get($theme, brdrD);
    height: 59px;
    font-size: 20px;
    border-radius: 0;
    border-radius: 4px;
    font-family: map-get($theme, fontFamily);
    // padding: 16px 12px;
    padding: 11px 24px;
    color: map-get($theme, text);
    border: 1px solid map-get($theme, text);
    width: 100%;

    &.hs-button {
      @include font($fontH);
    }
    @include media-breakpoint-down(md) {
      height: 56px;
    }
    &:hover,
    &:focus,
    &:active {
      border: 1px solid map-get($color, dark);
    }
    &:active {
      background: rgba(map-get($theme, accent), 0.1);
      border: 1px solid map-get($color, dark);
    }

    &.error {
      border-color: map-get($color, danger);
    }
  }
  .hs-input {
    &:not([type="radio"]):not([type="checkbox"]) {
      width: 100% !important;
    }
    &.invalid {
      &.error {
        border-color: map-get($color, danger);
      }
    }
  }
  .input {
    margin-right: 0 !important;
  }

  .hs-fieldtype-text,
  .hs-fieldtype-select,
  .hs_error_rollup,
  .hs-fieldtype-radio {
    .no-list {
      margin-bottom: 0;
      padding-left: 0;
      color: map-get($color, danger);
      @include notaList;

      .hs-error-msg,
      .hs-main-font-element {
        text-transform: none;
        @include font($font);
      }
    }
  }
  .hs_error_rollup {
    ul {
      li {
        label {
          display: none;
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    border-radius: 50px;
    @include font($fontB);
    @include media-breakpoint-down(sm) {
      margin-top: 35px;
      padding-top: 0;
    }
    .hs-button {
      line-height: 98%;
      border-radius: 48px !important;
      background: transparent;
      color: map-get($theme, text);
      border: 2px solid map-get($theme, text) !important;
      max-width: 137px;
      text-transform: uppercase;
      font-weight: bold;
      @include font($fontB);
      height: unset !important;
      padding: 14px 24px;
      &:hover,
      &:active {
        background: map-get($theme, color) !important;
        color: map-get($theme, bg);
      }
      // &:focus {
      //   &:hover {
      //     color: map-get($theme, color);
      //     border: map-get($theme, color);
      //     background: map-get($theme, bg) !important;
      //   }
      // }
    }
  }
  .hs-fieldtype-select {
    .hs-input {
      height: 59px;
      font-size: 20px;
      border-radius: 0;
      border-radius: 4px;
      font-family: map-get($theme, fontFamily);
      font-weight: 400;
      padding: 16px 12px;
      color: map-get($theme, colorL);
      border: 1px solid map-get($theme, brdrD);
      width: 100%;
      background: map-get($theme, bg);
    }
  }

  fieldset {
    max-width: 100% !important;
  }
}

.copyText {
  position: relative;
  input {
    padding: 0;
    width: 100%;
    padding: 8px 10px;
    padding-right: 2em;
    border-radius: 5px;
    text-overflow: ellipsis;
    background: rgba(map-get($color, accent), 0.16);
    border: 1px solid rgba(map-get($color, accent), 0.3);
    &,
    &:hover,
    &:focus,
    &:active {
      outline: 0 none;
    }
  }
  .ico {
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    position: absolute;
    align-items: stretch;
    button {
      width: 2em;
      height: 100%;
      display: block;
      background: transparent;
      border: 0 none transparent;
    }
  }
}

.btn-outline-primary:focus {
  background: transparent;
  color: map-get($color, primary);
}

// .bootstrap-datetimepicker-widget .datepicker {
//   width: 100%;
//   &:before {
//     display: none;
//   }
// }

// .rating {
//   span.star {
//     color: rgba(#929292, 0.5);
//     &.active,
//     &.active ~ span.star {
//       color: #f7ae00;
//     }
//   }
// }

// .selectBox {
//   position: relative;
//   &:after,
//   &:before {
//     top: 0;
//     right: 0;
//     content: "";
//     width: rem(32px);
//     position: absolute;
//     text-align: center;
//     pointer-events: none;
//   }
//   &:before {
//     top: 50%;
//     z-index: 1;
//     height: 1em;
//     line-height: 1em;
//     margin-top: -0.5em;
//     content: $i-arrow_drop_down;
//     font-family: $icomoon-font-family;
//   }
//   &:after {
//     bottom: 0;
//     background: #fff;
//   }
//   & > select {
//     width: 100%;
//     height: 100%;
//     border: 0 none;
//     padding: rem(5px) rem(32px) rem(5px) rem(5px);
//   }
// }
.legal-consent-container .hs-error-msgs {
  @include notaList;
  label {
    color: map-get($color, danger) !important;
    text-transform: inherit;
    font-weight: normal;
    list-style: none;
  }
}
