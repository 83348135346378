.intro {
  padding: 24px 0;
  .about {
    @extend .h4;
    mark {
      //  margin-left: calc(var(--mark-x) * -1);
    }
  }
  .action {
    width: 100%;
    margin-top: 36px;
    text-align: center;
  }
  @include media-breakpoint-down(map-get($global, device-switch)) {
    padding-bottom: 0;
    .about {
      mark {
        // padding: 0;
        // border: none;
        // background: transparent;
        // padding: 0px var(--mark-x);
        padding: 0px var(--mark-x) 3px;
        line-height: 45px;
      }
    }
    .about {
      font-size: 30px;
      line-height: 125%;
      @include font($fontB);
    }
  }
}
