.pageNav {
  padding: 40px 0;
  .grid {
    @include notaList;
    @include make-row();
    li {
      @include make-col-ready();
      display: flex;
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
      @include media-breakpoint-up(xl) {
        @include make-col(6);
      }
      @include media-breakpoint-down(lg) {
        .card {
          &.rippleCard {
            height: auto;
          }
        }
        &:last-child {
          .card {
            &.rippleCard {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 0;
  }
}
