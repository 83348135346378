.howGaCanAppear {
  .pageContent {
    .imgTitle {
      padding-top: 35px;
      @include media-breakpoint-down(md) {
        padding-top: 18px;
      }
    }
  }
}
