.dropdown-right {
  .dropdown-menu {
    right: 0;
    left: auto;
  }
}

.modalOverlay.active {
  background: rgba(map-get($color, dark), 0.8);
}

mark,
.mark {
  padding: 2px var(--mark-x);
  @include media-breakpoint-down(md) {
    padding: 0px var(--mark-x);
  }
}

.stretched-link {
  &:after {
    pointer-events: all;
  }
}

// .bootstrap-datetimepicker-widget {
//   padding: 5px;
//   min-width: 260px;

//   table {
//     th {
//       padding: 5px;
//     }
//     td {
//       &.day {
//         padding: 8px;
//         font-size: 12px;
//       }
//       &.active {
//         &,
//         &:hover {
//           background: map-get($color, accent);
//         }
//       }
//     }
//   }
//   .datepicker {
//     width: 100%;
//     &:before {
//       display: none;
//     }
//   }
//   .timepicker {
//     .timepicker-picker {
//       a.btn {
//         color: map-get($color, accent);
//       }
//     }
//     .btn-primary {
//       background: map-get($color, accent);
//       border-color: map-get($color, accent);
//     }
//     table {
//       td {
//         height: 40px;
//         line-height: 40px;
//         // .timepicker-minute {
//         //   pointer-events: none;
//         // }
//       }
//     }
//     .timepicker-picker {
//       a.btn {
//         border-radius: 50%;
//         &,
//         span {
//           margin: 0;
//           padding: 0;
//           width: 40px;
//           height: 40px;
//         }
//         span {
//           font-size: 2em;
//           line-height: 40px;
//         }
//       }
//     }
//   }
// }
