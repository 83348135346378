.popup {
  color: map-get($theme, color);
  &.popupVideo {
    background: transparent;
    .popHeader {
      height: 0;
      padding: 0;
      z-index: 2;
      min-height: 0;
      overflow: visible;
      position: absolute;
      border: 0 none transparent;
      // .close {
      //   top: 3px;
      //   right: 3px;
      //   padding: 3px;
      //   line-height: 1;
      //   width: rem(36px);
      //   height: rem(36px);
      //   position: absolute;
      //   min-width: rem(36px);
      //   font-size: rem(24px);
      //   @media only screen and (min-width: 768px) {
      //     background: transparent;
      //   }
      // }
      @media only screen and (max-width: 960px) and (max-height: 640px) {
        .close {
          right: 0;
          transform: translateY(100%);
          // background: rgba(map-get($theme, color), 0.7);
        }
      }
    }
  }

  &.popupPageViewer {
    background: transparent;
    --app-page-gutter-x: 120px;

    .page {
      width: 100%;
      max-width: 1184px;
      margin: 30px auto;
      border-style: solid;
      border-width: 24px 0;
      background: map-get($theme, bg);
      border-color: map-get($color, primary);
      padding: calc(var(--app-page-gutter-x, 128px) - 24px) var(--app-page-gutter-x, 128px)
        var(--app-page-gutter-x, 128px);
      @include media-breakpoint-down(map-get($global, device-switch)) {
        padding-bottom: 0;
        // border-width: 24px 0 60px;
        padding: 0 var(--app-page-gutter-x, 128px) 0;
      }
      @include media-breakpoint-down(md) {
        border-width: 24px 0 60px;
      }
      // @include media-breakpoint-down(md) {
      //   padding: 0 var(--app-page-gutter-x, 128px) 0;
      // }
      .eyebrowText {
        padding-bottom: 20px;
        text-transform: uppercase;
        @media only screen and (max-width: 1350px) {
          padding-bottom: 0;
          margin-right: auto;
        }
        @include media-breakpoint-down(map-get($global, device-switch)) {
          padding-bottom: 0;
          margin-right: auto;
          max-width: calc(100% - 70px);
        }
      }
      .pageHeader {
        position: relative;
        margin-bottom: 35px;
        padding-bottom: 30px;
        @include media-breakpoint-down(md) {
          padding-bottom: 25px;
        }
        &::before {
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          content: "";
          position: absolute;
          border-radius: 10em;
          background-color: map-get($theme, brdrD);
        }

        .h2 {
          @include font($fontH);
          color: map-get($theme, color);
        }
      }
      .pageContent {
        .subHead {
          font-size: 36px;
          line-height: 135%;
          @include font($fontB);
        }
        .details {
          padding: 26px 0;
          font-size: 22px;
          line-height: 160%;
        }
        .imgTitle {
          font-size: 26px;
          line-height: 150%;
          text-align: center;
          margin-bottom: 25px;
          @include font($fontB);
          &.aboutImgHead {
            margin-bottom: 20px;
            &.aboutTab {
              @include media-breakpoint-down(lg) {
                margin-bottom: unset;
              }
            }
          }
          @include media-breakpoint-down(lg) {
            font-size: 24px;
            margin-bottom: 1px;
            &.imghead {
              margin-bottom: 24px;
            }
          }
          &.imgSp {
            padding-top: 57px;
            @include media-breakpoint-down(md) {
              padding-top: 27px;
            }
          }
        }
        .card {
          &._highlgt {
            margin: 64px 0;

            .cardFooter,
            .cardContent {
              padding: 30px;
            }

            @include media-breakpoint-down(map-get($global, device-switch)) {
              .cardFooter {
                padding: 0 30px 30px;
              }
            }

            &._primary {
              @include media-breakpoint-up(md) {
                margin-bottom: 120px;
              }
              @include media-breakpoint-down(md) {
                margin-bottom: 50px;
              }
            }
          }
        }
        .form {
          padding: 80px;
          border-radius: 8px;
          margin: 65px 0 125px;
          background: map-get($theme, bgL);
          @include media-breakpoint-down(md) {
            margin: 50px 0;
            padding: 36px 24px;
          }
        }
      }
      .pageFooter {
        display: flex;
        border-top: 1px solid map-get($theme, brdrD);
        margin: 0 calc(var(--app-page-gutter-x, 128px) * -1) calc(var(--app-page-gutter-x, 128px) * -1);
        .linkCard {
          padding: 0 64px;
          & + .linkCard {
            border-left: 1px solid map-get($theme, brdrD);
          }
          @include media-breakpoint-up(map-get($global, device-switch)) {
            &:first-child {
              padding-left: var(--app-page-gutter-x, 128px);
            }
            &:last-child {
              padding-right: var(--app-page-gutter-x, 128px);
            }
          }
        }
      }

      .close {
        border: 0;
        top: 30px;
        right: 13px;
        position: fixed;
        font-size: 50px;
        background: transparent;
        color: map-get($color, light);
      }

      @media only screen and (max-width: 1350px) {
        &,
        .close {
          position: relative;
        }
        .close {
          top: 0;
          right: 0;
          border: 0;
          padding: 24px;
          font-size: 50px;
          background: transparent;
          color: map-get($color, dark);
        }
      }
      @include media-breakpoint-down(sm) {
        .close {
          font-size: 44px;
        }
      }
    }
    .popHeader {
      padding-top: 30px;
      position: absolute;
      padding-right: 45px;

      border: 0 none transparent;
    }
    .popContent {
      padding: 0;
      & > * {
        pointer-events: all;
      }
    }

    @include media-breakpoint-down(map-get($global, device-switch)) {
      --app-page-gutter-x: 25px;
      .page {
        margin: 0;
        position: relative;
        .pageFooter {
          margin: 0;
          flex-direction: column-reverse;
          margin: 0 calc(var(--app-page-gutter-x, 128px) * -1);
          .linkCard {
            padding: 0 var(--app-page-gutter-x) 0px;
            &:last-child {
              padding: 0 var(--app-page-gutter-x);
            }
            & + .linkCard {
              border-left: 0;
              border-bottom: 1px solid map-get($theme, brdrD);
              text-align: center;
            }
          }
        }
        .pageHeader {
          mark {
            line-height: 48px;
            padding: 0px var(--mark-x) 3px;
          }
        }
        .pageContent {
          // padding: 0 var(--app-page-gutter-x);
          .subHead {
            font-size: 30px;
          }
          .details {
            font-size: 20px;
          }
        }
      }
      .popHeader {
        height: 0;
        min-height: 0;
        padding-top: 0;
        position: relative;
      }
      .popContent {
        padding: 0px;
        overflow-x: hidden;
      }
    }
  }

  &.popupLeaveSite {
    &.popupPageViewer {
      text-align: center;
      --app-page-gutter-x: 100px;
      .heading {
        font-size: 36px;
        line-height: 135%;
        margin-bottom: 36px;
        @include font($fontM);
        letter-spacing: -0.72px;
      }
      .content {
        font-size: 26px;
        margin-bottom: 40px;
        @include font($fontB);
        letter-spacing: -0.1px;
      }
      .page {
        margin: auto;
        max-width: 944px;
        border-radius: 8px;
        position: relative;
        padding-top: 82px;
        padding-bottom: 82px;
        border: 0 none transparent;
      }
      .stick {
        padding: 0;
      }
      .action {
        .btn {
          margin: 0 22px;
        }
      }

      @media only screen and (max-width: 1350px) {
        .stick {
          left: 0;
          width: 100%;
          display: flex;
          transform: translateX(0);
          justify-content: flex-end;
        }
        .close {
          padding: 0;
        }
        .page {
          padding-top: 40px;
        }
        .heading {
          padding-top: 16px;
        }
      }

      @include media-breakpoint-down(map-get($global, device-switch)) {
        .popContent {
          padding: 8px;
          display: flex;
          align-items: center;
        }
        .heading,
        .content {
          font-size: 26px;
          margin-bottom: 38px;
          @include font($fontM);
        }

        .page {
          margin: auto;
          padding: 8px;
          position: relative;
        }
        .action {
          margin-bottom: 24px;
          .btn {
            margin: 4px 22px;
          }
        }
      }
      @include media-breakpoint-down(sm) {
        .page {
          padding: 8px 6px;
        }
      }
      @media only screen and (max-width: 374px) {
        .action {
          .btn {
            margin: 10px 8px;
          }
        }
      }
    }
  }

  .hs-fieldtype-text.field.hs-form-field {
    padding-bottom: 30px;
  }
}
