.card {
  // &Header {
  // }
  // &Footer {
  // }
  // &Content {
  // }

  &._highlgt {
    align-items: center;
    box-shadow: none;
    .cardFooter,
    .cardContent {
      padding: 40px 35px;
    }
    .cardContent {
      width: 100%;
    }
    .cardFooter {
      width: auto;
      .btn {
        min-width: 202px;
        padding: 11px 24px;
        text-transform: uppercase;
        &.btn-w {
          @include media-breakpoint-down(xl) {
            display: flex;
            min-height: 48px;
            align-items: center;
            justify-content: center;
          }
        }
      }

      @include media-breakpoint-down(xl) {
        padding: 0 35px 40px;
      }
    }
    @include media-breakpoint-up(map-get($global, device-switch)) {
      flex-direction: row;

      .cardFooter,
      .cardContent {
        padding: 64px 60px;
      }
      
      &._primary {
        .cardFooter {
          .btn {
            min-width: 352px;
          }
        }
      }
    }
    @include media-breakpoint-down(xl) {
      align-items: flex-start;
    }
  }

  &._light {
    --bs-bg-opacity: 1;
    --bs-text-opacity: 1;
    border: 1.5px solid map-get($cont, light);
    --bs-light-rgb: #{toRGBList(map-get($color, light))};
    --bs-contrast-rgb: #{toRGBList(map-get($cont, light))};
    color: rgba(var(--bs-contrast-rgb), var(--bs-text-opacity));
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  }

  &._primary {
    --bs-bg-opacity: 1;
    --bs-text-opacity: 1;
    --bs-light-rgb: #{toRGBList(map-get($cont, primary))};
    --bs-primary-rgb: #{toRGBList(map-get($color, primary))};
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
  }
  &._accent {
    --bs-bg-opacity: 1;
    --bs-text-opacity: 1;
    --bs-light-rgb: #{toRGBList(map-get($cont, accent))};
    --bs-accent-rgb: #{toRGBList(map-get($color, accent))};
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
    background-color: rgba(var(--bs-accent-rgb), var(--bs-bg-opacity));
  }
  &.lighten {
    --bs-bg-opacity: 0.5;
    &._primary {
      --bs-bg-opacity: 0.65;
    }
    &._accent {
      --bs-bg-opacity: 0.35;
    }
  }
  &.rippleCard {
    overflow: hidden;
    --app-icon-bg: #{map-get($theme, color)};
    --app-card-bg: #{map-get($theme, write)};
    --app-card-color: #{map-get($theme, color)};
    --app-icon-color: #{map-get($theme, write)};

    color: var(--app-card-color);
    background: var(--app-card-bg);
    box-shadow: 0px 0px 16px 4px rgba(map-get($color, dark), 0.1);
    width: 100%;
    height: calc(100% - 35px);
    margin-bottom: 35px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 25px;
    }
    .cardHeader {
      padding: 0;
      position: static;
      justify-content: flex-end;
      height: 190px;
      .icon {
        width: 233px;
        height: 233px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        transform: translate(41px, -41px);

        &,
        &:before {
          border-radius: 50%;
          color: var(--app-icon-color);
          background: var(--app-icon-bg);
          transition:
            transform ease-in 300ms,
            color ease-in 300ms;
        }
        &:before {
          top: 0;
          right: 0;
          z-index: 0;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
        }
        img,
        picture {
          z-index: 5;
          position: relative;
        }
      }
    }
    .cardFooter {
      margin-top: auto;
      padding: 0 30px 30px;
      @include media-breakpoint-down(md) {
        padding: 0 20px 35px;
      }
    }
    .cardContent {
      justify-content: center;
      padding: 15px 30px;
      @include font($fontB);
      @include media-breakpoint-down(md) {
        padding: 15px 20px;
      }
      h3 {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 15px;
      }
      .card-details {
        font-size: 22px;
        @include font($font);
        margin-bottom: 5px;
      }
    }
    &:hover,
    &:focus,
    &:active {
      --app-card-bg: var(--app-icon-bg);
      --app-card-color: var(--app-icon-color);
      .icon {
        &:before {
          transform: scale(3);
        }
      }
    }
    @include media-breakpoint-down(map-get($global, device-switch)) {
      .cardContent {
        .card-details {
          font-size: 20px;
          line-height: 160%;
          margin-bottom: 22px;
        }
      }
    }
    &.linkCard {
      margin-bottom: 0;
      border-radius: 0;
      box-shadow: none;
      .cardHeader {
        height: auto;
        justify-content: flex-start;
        padding-top: 36px;
        .icon {
          width: 86px;
          height: 86px;
          transform: none;
          padding: 15px;
        }
      }
      .cardContent {
        padding: 15px 0;
      }
      .cardFooter {
        padding-left: 0;
        padding-right: 0;
      }
      @include media-breakpoint-down(map-get($global, device-switch)) {
        .cardHeader {
          justify-content: center;
        }
        .cardContent {
          align-items: center;
          text-align: center;
        }
        .cardFooter {
          justify-content: center;
        }
      }
    }
  }
}
