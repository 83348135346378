::selection,
::-moz-selection {
  background: rgba(map-get($theme, selection), 0.3);
}

* {
  @include scrollbar(8px, map-get($theme, scroll), rgba(map-get($theme, scroll), 0.1));
  &:before,
  &:after {
    pointer-events: none;
  }
}

html,
body {
  width: 100%;
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0;
  font-size: $baseFontSize;
  @if variable-exists(minWidth) {
    min-width: $minWidth;
  }
  @if variable-exists(maxWidth) {
    max-width: $maxWidth;
  }
}

html {
  overflow: scroll; // /* has to be scroll, not auto
  overflow-y: scroll;
  overflow-x: hidden;
  text-rendering: auto;
  -webkit-overflow-scrolling: touch;
}

body {
  color: map-get($theme, text);
  background: map-get($theme, bg);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // -webkit-backface-visibility: hidden;
  font-family: map-get($theme, fontFamily);
}

small,
.small {
  line-height: 135%;
  font-size: 0.81818181818181818181818181818182em; // 18px
}

strong,
.strong {
  @include font($fontB);
}

label,
.label {
  font-size: 16px;
  line-height: 100%;
  @include font($fontB);
  letter-spacing: -0.02em;
}

a,
button {
  @extend %animateFast;
  & > * {
    pointer-events: none;
  }
}

a {
  color: map-get($theme, link);
  // &,
  // & > * {
  //   outline: none;
  // }
  & > * {
    pointer-events: none;
  }
  &,
  &:hover,
  &:focus,
  &:active {
    cursor: pointer;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active,
  &.active {
    color: map-get($theme, link);
  }
}

b,
strong {
  font-weight: normal;
  @include font(map-get($theme, fontB));
}

svg {
  &.defs {
    width: 0;
    height: 0;
    position: absolute;
  }
}

img {
  max-width: 100%;
  height: auto !important;
}

mark,
.mark {
  --mark-x: #{em(16px, 70px)};
  display: inline;
  line-height: 100%;
  border-radius: 4px;
  box-sizing: content-box;
  padding: 0 var(--mark-x);
  box-decoration-break: clone;
  border: 0.5px solid map-get($color, dark);
  background: map-get($theme, marker) padding-box;
  // box-shadow: 0.15em 0 0 map-get($theme, marker), -0.15em 0 0 map-get($theme, marker),
  //   -0.15em 0 0 1.5px rgba(map-get($color, dark), 0.5), 0.15em 0 0 1.5px rgba(map-get($color, dark), 0.5);
  // & + & {
  //   padding-left: 0;
  //   padding-right: 0;
  //   border-radius: 0;
  //   border-left-color: transparent;
  //   border-right-color: transparent;
  // }
  // &:first-of-type {
  //   padding-right: 0;
  //   border-radius: 4px 0 0 4px;
  //   border-right-color: transparent;
  //   border-left-color: map-get($color, dark);
  // }
  // &:last-child {
  //   border-radius: 0 4px 4px 0;
  //   padding-right: var(--mark-x);
  //   border-right-color: map-get($color, dark);
  // }
}

picture {
  display: inline-block;
}

figure {
  margin-bottom: 0;
}

// fieldset {
//   overflow: hidden;
//   padding: rem(7px) rem(20px);
//   border-radius: rem($brdRad);
//   border: rgba(map-get($color, dark), 0.36) rem(1px) solid;
// }

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-family: map-get($global, titleFont);
  small,
  .small {
    font-size: 0.575em;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  letter-spacing: -0.02em;
}

h1,
.h1 {
  font-size: 70px;
  line-height: 100%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h2,
.h2 {
  font-size: 48px;
  line-height: 125%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h3,
.h3 {
  font-size: 48px;
  line-height: 135%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h4,
.h4 {
  font-size: 36px;
  line-height: 135%;
  @include font($fontM);
  strong {
    @include font($fontB);
  }
  &.normal {
    @include font($font);
  }
}

h5,
.h5 {
  font-size: 28px;
  line-height: 100%;
  @include font($fontB);
  strong {
    @include font($fontH);
  }
  &.normal {
    @include font($font);
  }
}

h6,
.h6 {
  font-size: 28px;
  line-height: 135%;
  @include font($fontM);
  strong {
    @include font($fontB);
  }
  &.normal {
    @include font($font);
  }
}

// .container-fluid {
//   &.pageWrapper {
//     padding: 0;
//     margin: 0 auto;
//     max-width: 1200px;
//     @include box-shadow(0 0 rem(15px) rgba(map-get($color, dark), 0.5));
//   }
// }

.fw,
.fh {
  position: relative;
}

.fw {
  width: 100%;
}

.fh {
  height: 100%;
}

video.asBackground {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  pointer-events: none;
}

// .owl-nav button.owl-prev,
// .owl-nav button.owl-next,
// button.owl-dot {
//   background: transparent;
//   border: 0 none transparent;
// }

.container {
  &,
  &-fluid {
    --bs-gutter-x: 40px;
  }
}

@include media-breakpoint-down(map-get($global, device-switch)) {
  html,
  body {
    font-size: 20px;
    line-height: 160%;
    letter-spacing: 0;
  }

  small,
  .small {
    font-size: 18px;
    line-height: 135%;
  }

  h1,
  .h1 {
    font-size: 48px;
    line-height: 125%;
  }

  h2,
  .h2 {
    font-size: 36px;
    line-height: 125%;
  }

  h3,
  .h3 {
    font-size: 30px;
    line-height: 135%;
  }

  h4,
  .h4 {
    font-size: 26px;
    line-height: 135%;
  }

  h5,
  .h5 {
    font-size: 28px;
    line-height: 100%;
  }

  h6,
  .h6 {
    font-size: 26px;
    line-height: 135%;
  }
  .container {
    &,
    &-fluid {
      --bs-gutter-x: 48px;
    }
    @media only screen and (max-width: 992px) {
      & {
        max-width: 100% !important;
      }
    }
  }
}
