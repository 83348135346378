// ------------------------ //
// ***** WEIGHT CHART ***** //
// ------------------------ //
// #region -= WEIGHT CHART
// 100    Extra Light or Ultra Light
// 200    Light / Thin
// 300    Book or Demi / Thin
// 400    Normal or Regular
// 500    Medium
// 600    Semibold, Demibold
// 700    Bold
// 800    Black, Extra Bold or Heavy
// 900    Extra Black, Fat, Poster or Ultra Black
// #endregion -= WEIGHT CHART

@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.subTitle {
  font-size: 24px;
  line-height: 150%;
  @include font($fontB);
}

%f {
  // &,
  &-body {
    &-bold,
    &-regular {
      font-size: 22px;
      line-height: 160%;
      letter-spacing: 0;
      &-sm {
        font-size: 18px;
        line-height: 135%;
      }
    }
  }
  &-bold {
    @include font($fontB);
  }
}

.f {
  // &,
  &-body {
    &-regular {
      @extend %f-body-regular;
      &-sm {
        @extend %f-body-regular-sm;
      }
    }
    &-bold {
      @extend %f-bold;
    }
  }
}

.eyebrowText {
  font-size: 18px;
  line-height: 100%;
  @include font($fontB);
  letter-spacing: -0.02em;
}

.legal {
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0;
  @include font($font);
}

@include media-breakpoint-down(map-get($global, device-switch)) {
  .eyebrowText {
    line-height: 125%;
  }
}
