:root {
  // THEME
  @each $key, $value in $theme {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // COLOR
  @each $key, $value in $color {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // CONTRAST
  @each $key, $value in $cont {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-cont-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-cont-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // HOVER
  @each $key, $value in map-get($theme, hover) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-hover-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-hover-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // HEADER
  @each $key, $value in map-get($theme, header) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-header-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-header-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
  // FOOTER
  @each $key, $value in map-get($theme, footer) {
    @if (type-of($value) != "map") {
      --#{$cssVarPrefix}-footer-#{$key}: #{$value};
      @if (type-of($value) == color) {
        --#{$cssVarPrefix}-footer-#{$key}-rgb: #{toRGBList($value)};
      }
    }
  }
}
