.page.aboutGA {
  .card {
    &.imgCard,
    &.icoCard {
      //  background-color: map-get($theme, bgL);
      // padding: 30px;
      box-shadow: none;
    }
    &.imgCard {
      margin: 30px 0;
      figure {
        text-align: center;
        padding: 30px 0;
        background: map-get($theme, bgI);
        border-radius: 8px;
        picture {
          padding: 0 20px;
        }
        @include media-breakpoint-down(md) {
          // background: transparent;
          // padding: unset;
        }
        .tabimgTitle {
          padding: 0 50px;
          margin: 0 auto;
          text-align: center;
          font-size: 22px;
          max-width: 857px;
          //  min-height: 100px;
          min-height: 70px;
          @include media-breakpoint-down(md) {
            font-size: 18px;
            line-height: 135%;
            padding: 0 30px;
            max-width: 100%;
            //min-height: 100px;
            min-height: unset;
          }
        }
        figcaption {
          font-size: 18px;
          margin-top: 25px;
        }
      }
    }
    &.icoCard {
      flex-direction: row;
      align-items: center;
      margin: 65px 0 130px;
      background: map-get($theme, bgI);
      padding: 36px;
      .cardHeader {
        width: auto;
      }
      .cardContent {
        padding-left: 20px;
        p {
          font-size: 26px;
          line-height: 150%;
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(map-get($global, device-switch)) {
    .card {
      &.icoCard {
        flex-direction: column;
        margin: 50px 0;
        .cardContent {
          padding: 10px;
          p {
            font-size: 24px;
            line-height: 150%;
          }
        }
      }
    }
  }
}
