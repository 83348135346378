.banner {
  display: flex;
  position: relative;
  align-items: center;
  .bannerWrap {
    position: relative;
    aspect-ratio: 1284/320;
  }
  .bg {
    top: 0;
    left: var(--bs-gutter-x);
    z-index: 0;
    width: calc(100% - (var(--bs-gutter-x)) * 1);
    height: 100%;
    position: absolute;
    @include media-breakpoint-down(md) {
      // min-height: 160px;
    }
    @include media-breakpoint-down(sm) {
      width: 100vw;
      left: calc(var(--bs-gutter-x) * -0.5);
    }
    img,
    figure,
    picture {
      width: 100%;
      height: 100%;
    }
    img {
      object-fit: cover;
      max-width: initial;
      height: 100% !important;
      object-position: right top;
    }
  }
  .textWrap {
    z-index: 5;
    width: 100%;
    position: relative;
    line-height: normal;
    // top: 0;
    // left: 0;
    // z-index: 0;
    // width: 100%;
    // height: 100%;
    // position: absolute;
  }
  .title {
    // line-height: 135%;
    line-height: 125%;
    @media (max-width: 1400px) and (min-width: 1200px) {
      font-size: 60px;
    }
  }

  @include media-breakpoint-down(map-get($global, device-switch)) {
    height: auto;
    min-height: initial;
    flex-direction: column;
    margin-top: calc(var(--app-header-height, 128px) * -1);
    .bg {
      height: auto;
      position: relative;
      margin-bottom: 36px;
    }
    // .textWrap {
    //   margin-bottom: 28px;
    // }
    .title {
      line-height: 125%;
      @include font($fontBK);
    }
  }
}
