@mixin card($c: map-get($color, light)) {
	padding: 0;
	color: inherit;
	display: flex;
	background: $c;
	position: relative;
	margin-bottom: 20px;
	@extend %animateFast;
	text-decoration: none;
	flex-direction: column;
	border-radius: $matBorderRadius;
	box-shadow: 0 3px 1px -2px rgba(map-get($color, dark), 0.2), 0 2px 2px 0 rgba(map-get($color, dark), 0.14),
		0 1px 5px 0 rgba(map-get($color, dark), 0.12);
	&.hover {
		box-shadow: 0 6px 4px -8px rgba(map-get($color, dark), 0.2), 0 8px 8px 0 rgba(map-get($color, dark), 0.14),
			0 2px 10px 0 rgba(map-get($color, dark), 0.12);
	}
	&.transparent {
		background: transparent;
	}
	&.magnet {
		&-x {
			border-radius: 0;
			&:first-child {
				border-radius: $matBorderRadius 0 0 $matBorderRadius;
			}
			&:last-child {
				border-radius: 0 $matBorderRadius $matBorderRadius 0;
			}
		}
		&-y {
			border-radius: 0;
			margin-top: 0 !important;
			margin-bottom: 0 !important;
			&:first-child {
				border-radius: $matBorderRadius $matBorderRadius 0 0;
			}
			&:last-child {
				border-radius: 0 0 $matBorderRadius $matBorderRadius;
			}
		}
	}

	.cardHeader,
	.cardFooter,
	.cardContent {
		--nitro-gutter-x: 10px;
		width: 100%;
		display: flex;
		position: relative;
		padding: 0 var(--nitro-gutter-x);
		.row {
			margin: 0 calc(var(--nitro-gutter-x) * -1);
			& > .table-responsive {
				margin: 0 -1px;
			}
		}
	}

	.cardHeader {
		padding: var(--nitro-gutter-x);
		border-radius: $matBorderRadius $matBorderRadius 0 0;
		.actions {
			width: 100%;
			display: flex;
			&.L {
				margin-right: auto;
			}
			&.R {
				margin-left: auto;
			}
		}
	}

	.cardContent {
		flex-direction: column;
	}

	.cardFooter {
		border-radius: 0 0 $matBorderRadius $matBorderRadius;
	}
}

.card,
%card {
	@include card;
	&.noShadow {
		box-shadow: none;
	}
	// &.hover,
	// &:hover,
	// &:focus,
	// &:active {
	// 	text-decoration: none;
	// 	box-shadow: 0 6px 4px -8px rgba(map-get($color, dark), 0.2), 0 8px 8px 0 rgba(map-get($color, dark), 0.14),
	// 		0 2px 10px 0 rgba(map-get($color, dark), 0.12);
	// }
}

// .card {
// 	&.hasFooter {
// 		padding-bottom: 50px;
// 		.cardFooter {
// 			left: 0;
// 			bottom: 0;
// 			margin: 0;
// 			width: 100%;
// 			position: absolute;
// 		}
// 	}
// }

.cards {
	padding: 10px;
	@extend %notaList !optional;
	& > li,
	& > article {
		background: map-get($color, light);
		@extend %card !optional;
	}
}
