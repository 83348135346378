@use "sass:map";

$header: map-get($theme, header);

header.header {
  z-index: 15;
  position: relative;
  background: transparent;
  color: map-get($header, text);
  .dropdown {
    &-item {
      @include font($fontB);
    }
    &-menu {
      @extend %animateFast;
      & {
        --bs-dropdown-border-radius: 10px;
        // --bs-dropdown-item-padding-x: 20px;
        // --bs-dropdown-item-padding-y: 10px;
        // --bs-dropdown-border-color: transparent;
        // --bs-dropdown-link-color: #{map-get($header, link)};
        // --bs-dropdown-link-hover-color: #{map-get($header, link)};
        // --bs-dropdown-link-hover-bg: #{map-get($header, ddHoverBg)};
        // --bs-dropdown-link-active-bg: #{map-get($header, ddActiveBg)};
        // --bs-dropdown-link-active-color: #{map-get($header, ddActiveTxt)};
        // --bs-dropdown-box-shadow: 0px 3px 6px #{rgba(map-get($color, dark), 0.16)};
        // --bs-dropdown-color: #212529;
        // --bs-dropdown-zindex: 1000;
        // --bs-dropdown-padding-x: 0;
        // --bs-dropdown-padding-y: 0.5rem;
        // --bs-dropdown-font-size: 1rem;
        // --bs-dropdown-min-width: 10rem;
        // --bs-dropdown-spacer: 0.125rem;
        // --bs-dropdown-bg: #fff;
        // --bs-dropdown-border-width: 1px;
        // --bs-dropdown-inner-border-radius: calc(0.375rem - 1px);
        // --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
        // --bs-dropdown-divider-margin-y: 0.5rem;
        // --bs-dropdown-link-disabled-color: #adb5bd;
        // --bs-dropdown-header-color: #6c757d;
        // --bs-dropdown-header-padding-x: 1rem;
        // --bs-dropdown-header-padding-y: 0.5rem;
      }
      min-width: 100%;
      box-shadow: var(--bs-dropdown-box-shadow);
    }
  }

  .navbar {
    --bs-navbar-padding-y: 33.75px;
    @include media-breakpoint-down(md) {
      --bs-navbar-padding-y: 19.75px;
    }
    .nav-link {
      line-height: 1em;
      @extend %animateFast;
      @include font($fontB);
      // & > span {
      //   position: relative;
      //   &:before {
      //     left: 0;
      //     right: 0;
      //     width: 0%;
      //     height: 1px;
      //     content: "";
      //     opacity: 0.5;
      //     margin: auto;
      //     bottom: -10px;
      //     position: absolute;
      //     @extend %animateFast;
      //   }
      // }
    }
    .nav-item {
      --bs-nav-link-font-size: #{map.get($theme, baseFontSize)};
      // &:not(:last-child) {
      //   border-right: 1px solid map-get($header, brdr);
      // }
      // &.hasBtn {
      //   .nav-link {
      //     color: #{map-get($header, linkSpot)};
      //   }
      // }
    }
    &-nav {
      --bs-nav-link-padding-x: 0;
      --bs-nav-link-padding-y: 14.5px;
      --bs-navbar-nav-link-padding-x: 24px;
      --bs-nav-link-color: #{map-get($header, link)};
      --bs-navbar-active-color: #{map-get($header, linkActive)};
      --bs-nav-link-hover-color: #{map-get($header, linkHover)};
      // --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
    }
  }

  @include media-breakpoint-down(map-get($global, device-switch)) {
    .bg-light {
      --bs-bg-opacity: 0;
    }
  }
}
