.page.howQuicklyGa {
  .pageContent {
    .subHead {
      padding-bottom: 64px;
      @include media-breakpoint-down(sm) {
        padding-bottom: 43px;
      }
    }
    .challenges {
      display: flex;
      padding-bottom: 90px;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      .card {
        &.challengeCard {
          // display: flex;
          // flex-direction: column;
          // margin: 0 auto;
          box-shadow: none;
          // max-width: 270px;
          text-align: center;
          position: relative;
          //   border-left: 1px solid map-get($theme, brdrD);
          border-radius: 0;
          padding: 0 39px;
          // max-width: 300px;
          min-width: 300px;
          max-width: 300px;
          &:after {
            position: absolute;
            content: "";
            top: 0;
            height: 224px;
            width: 1px;
            background: map-get($theme, brdrD);
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            left: 0;
          }
          &:first-child {
            &:after {
              display: none;
            }
          }
          @include media-breakpoint-down(lg) {
            min-width: unset;
            max-width: 300px;
          }
          &:first-child {
            border-left: 0;
          }
          .cardheader {
            min-height: 110px;
            text-align: center;
            img {
              max-width: 116px;
            }
          }
          .cardcontent {
            font-size: 26px;
            @include font($fontB);
            padding-top: 25px;
          }
        }
      }
      @include media-breakpoint-down(md) {
        flex-direction: column;
        .card {
          &.challengeCard {
            //   border-top: 1px solid map-get($theme, brdrD);
            border-left: 0;
            padding: 24px 18px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 0;
            &:after {
              position: absolute;
              content: "";
              top: 0;
              // left: 0;
              height: 1px;
              width: 168px;
              background: map-get($theme, brdrD);
              justify-content: center;
              align-items: center;
              border-radius: 10px;
              left: auto;
            }
            &:first-child {
              &:after {
                display: none;
              }
            }
          }
        }
      }
      @include media-breakpoint-down(sm) {
        padding-bottom: 25px;
      }
    }
    .capsuleTabs {
      .tab-content {
        position: relative;
        @include media-breakpoint-down(xl) {
          display: flex;
          justify-content: center;
        }
        .tab-pane {
          position: absolute;
          left: 0;
          top: 0;
          @include media-breakpoint-down(sm) {
            display: flex;
            justify-content: center;
            left: unset;
            top: unset;
          }
          img {
            @include media-breakpoint-down(sm) {
              max-height: 145px;
              display: flex;
              justify-content: center;
            }
            @media only screen and (max-width: 374px) {
              max-height: 120px;
            }
          }
        }
      }
    }
    .quickNote {
      font-size: 18px;
      padding-top: 15px;
      line-height: 135%;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 14px;
        line-height: 160%;
      }
    }
    .details {
      &.quik-details {
        font-size: 26px;
        @include font($fontB);
        text-align: center;
        padding-top: 40px;
        @include media-breakpoint-down(md) {
          text-align: left;
          font-size: 24px;
          padding-top: 25px;
        }
      }
    }
  }
  @include media-breakpoint-down(map-get($global, device-switch)) {
  }
}
