input[type="radio"],
input[type="checkbox"] {
  $obG: 6px;
  $obW: 22px;
  $obH: 22px;

  &.custom {
    padding: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0 none;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);

    & + label {
      cursor: pointer;
      min-height: $obH;
      line-height: $obH;
      margin: 0 8px 0 0;
      position: relative;
      font-weight: normal;
      word-wrap: break-all;
      word-wrap: break-word;
      display: inline-block;
      vertical-align: middle;
      font-size: $baseFontSize;
      padding-left: $obW + $obG;
      // background-position: 0 0;
      // background-repeat: no-repeat;

      @extend %nilSelect;

      &:after,
      &:before {
        top: 0px;
        left: 0px;
        content: " ";
        position: absolute;
      }

      &:after {
        top: -$obH * 0.5;
        left: -$obW * 0.5;
        width: $obW * 2;
        height: $obH * 2;
        background: rgba(#000, 0.2);
        transition-duration: 0.2s;
        -webkit-transition-duration: 0.2s;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

        border-radius: 50%;
        @include prefix(
          (
            transform: scale(0),
          ),
          webkit ms
        );
      }

      &:before {
        min-width: $obW;
        min-height: $obH;
        font-size: $obW;
        line-height: $obH;
        font-family: $icomoon-font-family;
        // background-repeat: no-repeat;
        // background-image: url("#{$img}/chkbox-mtl.png");
        content: $i-check_box_outline_blank;
      }
    }

    &:checked + label:before {
      color: map-get($theme, accent);
      // background-position: 0px -#{$obH};
      content: $i-check_box;
    }

    &:focus {
      & + label {
        &:after {
          background: rgba(#000, 0.2);

          @include prefix(
            (
              transform: scale(1),
            ),
            webkit ms
          );
        }
      }

      &:checked + label:after {
        background: rgba(map-get($color, accent), 0.2);
      }
    }

    &.disabled,
    &[disabled],
    &:disabled {
      & + label:before {
        opacity: 0.5;
        // background-position: 0px #{-$obH * 2};
      }

      &:checked {
        & + label:before {
          opacity: 0.5;
          // background-position: 0px #{-$obH * 3};
        }
      }
    }

    &.error {
      & + label:before {
        color: map-get($matColor, danger);
        // background-position: 0px #{-$obH * 4};
      }

      // &:checked + label:before {
      //     background-position: 0px -#{$obH};
      // }
    }

    // &.switch {
    //     margin: 0 5px;
    //     & + label {
    //         height: 30px;
    //         margin: 0 2px;
    //         text-indent: 0;
    //         min-width: 36px;
    //         background: none;
    //         line-height: 30px;
    //         position: relative;
    //         padding: 0 0 0 42px;
    //         &:after,
    //         &:before {
    //             top: 50%;
    //             @extend %animateFast;
    //         }
    //         &:before {
    //             left: 0;
    //             width: 36px;
    //             height: 12px;
    //             overflow: hidden;
    //             margin-top: -6px;
    //             background: rgba(#000, 0.38);
    //             border-radius: 20px;
    //         }
    //         &:after {
    //             left: 0px;
    //             width: 20px;
    //             height: 20px;
    //             content: ' ';
    //             background: #fff;
    //             position: absolute;
    //             margin: -10px 0 0 -1px;
    //             border-radius: 50%;
    //             @include box-shadow("0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)");
    //         }
    //     }
    //     &:checked + label {
    //         &:before {
    //             background: rgba(map-get($color, accent), 0.38);
    //         }
    //         &:after {
    //             left: 16px;
    //             margin-left: 0px;
    //             margin-right: -1px;
    //             background: map-get($color, accent);
    //         }
    //     }
    // }
  }
}

input[type="radio"] {
  &.custom:not(.checkbox) {
    & + label:before {
      // border-radius: 50%;
      content: $i-radio_button_unchecked;
      // background-image: url("#{$img}/radio-mtl.png");
    }

    &:checked {
      & + label:before {
        // background-color: $accent;
        content: $i-radio_button_on;
      }
    }
  }

  // &.chkBtn {
  //     & + label:before {
  //         background-image: none;
  //     }
  // }
}

.chkGroup {
  font-size: 0;

  input[type="radio"],
  input[type="checkbox"] {
    &.chkBtn {
      & + label {
        margin: 0;
        font-size: 1rem;
        border-radius: 0;
        border-left-width: 0;
      }

      &:checked {
        & + label {
          pointer-events: none;
          border-color: transparent;

          &:before {
            background: none;
          }
        }
      }

      &:first-child {
        & + label {
          border-left-width: 1px;
        }
      }

      &:last-child {
        & + label {
          border-left-width: 1px;
        }
      }
    }
  }
}

.radio label,
.checkbox label {
  margin-bottom: 0;
  min-height: 20px;
  padding-left: 20px;
}

label.checkbox,
label.radio {
  $obG: 10px;
  $obW: 22px;
  $obH: 22px;

  cursor: pointer;
  min-width: $obW;
  min-height: $obH;
  line-height: $obH;
  position: relative;
  font-weight: normal;
  margin: 0 5px 5px 0;
  display: inline-block;
  vertical-align: middle;
  background-position: 0 0;
  // font-size: rem(18px);
  // padding: 0 0 0 #{$obH + 10} !important;
  // /* nilSelect */

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & + & {
    margin-top: 0;
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
  }
  
  &:after {
    top: -$obH * 0.5;
    left: -$obW * 0.5;
    width: $obW * 2;
    height: $obH * 2;
    background: rgba(#000, 0.2);
    transition-duration: 0.2s;
    -webkit-transition-duration: 0.2s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    border-radius: 50%;
    @include prefix(
      (
        transform: scale(0),
      ),
      webkit ms
    );
  }

  // &:before {
  //     width: $obW;
  //     height: $obH;
  //     margin-right: 5px;
  //     position: relative;
  //     display: inline-block;
  //     background-repeat: no-repeat;
  //     background-image: url("#{$img}/chkbox-mtl.png");
  // }

  &:before {
    min-width: $obW;
    min-height: $obH;
    font-size: $obW;
    line-height: $obH;
    font-family: $icomoon-font-family;
    content: $i-check_box_outline_blank;
  }

  label {
    margin: 0;
    min-height: $obH;
    vertical-align: top;
    display: inline-block;
    padding: 0 0 0 ($obW + 5);
  }

  input[type="radio"],
  input[type="checkbox"] {
    border: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    clip: rect(0 0 0 0);
  }

  &.checked:before {
    content: $i-check_box;
    color: map-get($theme, accent);
  }

  &.disabled {
    &:before {
      opacity: 0.5;
    }

    &.checked {
      &:before {
        opacity: 0.5;
      }
    }
  }

  // &.focus { outline: 1px dotted; }

  &.focus,
  &:focus {
    &:after {
      background: rgba(#000, 0.2);

      @include prefix(
        (
          transform: scale(1),
        ),
        webkit ms
      );
    }

    &.checked:after {
      background: rgba(map-get($color, accent), 0.2);
    }
  }

  &.switch {
    height: 30px;
    margin: 0 2px;
    text-indent: 0;
    min-width: 48px;
    background: none;
    line-height: 30px;
    position: relative;
    padding: 0 0 0 45px !important;

    &:after,
    &:before {
      content: "";
      position: absolute;
      @extend %animateFast;
      top: calc(50% - 11px);
    }

    &:before {
      left: 0;
      width: 48px;
      height: 24px;
      overflow: hidden;
      border-radius: 20px;
      background: rgba(#000, 0.38);
    }

    &:after {
      left: 2px;
      width: 20px;
      height: 20px;
      content: " ";
      background: #fff;
      border-radius: 50%;
      top: calc(50% + 1px);
      transform: scale(0.85);
      margin: -10px 0 0 -1px;
      @include box-shadow("0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)");
    }

    &.checked {
      &:before {
        background: rgba(map-get($color, accent), 0.38);
      }

      &:after {
        left: 30px;
        margin-left: 0px;
        margin-right: -1px;
        transform: scale(1.6);
        background: map-get($color, accent);
      }
    }

    &.bool {
      $g: 5px;
      $w: 80px;
      $h: 40px;
      height: $h;
      min-width: $w + $g;
      padding-left: $w + $g !important;
      &:before {
        top: 0;
        width: $w;
        height: $h;
        content: "No";
        font-size: 14px;
        text-align: right;
        line-height: $h - $g;
        font-family: inherit;
        @include font($fontB);
        padding: 1px 18px 1px 12px;
        color: map-get($color, danger);
        background: rgba(map-get($color, danger), 0.18);
      }
      &:after {
        left: 7px;
        width: 0.6 * $h;
        height: 0.6 * $h;
        transform: scale(1);
        top: calc(50% - 2px);
        transform-origin: center;
        background: map-get($color, danger);
      }
      &.checked {
        &:before {
          content: "Yes";
          text-align: left;
          color: map-get($color, success);
          background: rgba(map-get($color, success), 0.18);
        }
        &:after {
          left: 47px;
          transform: scale(1.2);
          background: map-get($color, success);
        }
      }
    }
  }
}

label.radio {
  &:before {
    // border-radius: 50%;
    content: $i-radio_button_unchecked;
    // background-image: url("#{$img}/radio-mtl.png");
  }

  &.checked {
    &:before {
      // background-color: $accent;
      content: $i-radio_button_on;
    }
  }
}

.rating-select {
  direction: rtl;
  margin: 0px 5px;
  cursor: pointer;
  display: inline-block;
  unicode-bidi: bidi-override;

  // input.custom[type="checkbox"],
  input.custom[type="radio"] {
    & + label {
      min-height: 1em;
      margin: 0 -1px 0;
      line-height: 1em;
      padding-left: 1em;
      font-size: inherit;

      &:before {
        width: 1em;
        height: 1em;
        line-height: 1em;
        content: $i-star;
        background: none;
        font-family: "#{$icomoon-font-family}";
        color: rgba(map-get($theme, text), 0.4);
        text-shadow: 0px 0px 4px rgba(#000, 0.16);
      }

      &:after {
        left: 42%;
        top: -18px;
        opacity: 0;
        width: auto;
        height: 18px;
        color: #fff;
        font-size: 12px;
        padding: 0 10px;
        min-width: 18px;
        line-height: 1.6em;
        position: absolute;
        white-space: nowrap;
        content: attr(aria-rating);
        background: rgba(#333, 0.8);

        @include prefix(
          (
            transform: translate(-50%, 0%),
          ),
          ms webkit moz
        );
        border-radius: 10px;
      }

      &[aria-rating]:hover {
        &:after {
          opacity: 1;
        }
      }
    }

    &:checked {
      & + label {
        &:before {
          background: transparent;
          color: map-get($theme, accent);
        }

        &[aria-rating] {
          &:hover {
            &:after {
              opacity: 1;
            }
          }
        }
      }

      & ~ label {
        position: relative;

        &:before {
          // background-position: 0px 25%;
          color: gold;
          content: $i-star;
        }
      }
    }

    &[disabled] + label[aria-rating],
    &:disabled + label[aria-rating] {
      &:after {
        display: none;
      }
    }
  }

  &[readonly],
  &.readonly {
    pointer-events: none;

    input.custom[type="radio"] + label {
      pointer-events: none;
    }
  }

  &[disabled],
  &.disabled {
    pointer-events: none;

    // input.custom[type="checkbox"],
    input.custom[type="radio"] {
      & + label {
        pointer-events: none;

        &:before {
          background-position: 0px 50%;
        }

        &[aria-rating]:after {
          display: none;
        }
      }

      &:checked {
        & + label[aria-rating] {
          &:after {
            opacity: 1;
          }
        }

        & ~ label {
          position: relative;

          &:before {
            background-position: 0px 25%;
          }
        }
      }
    }
  }
}

.rating {
  font-size: 0;
  direction: rtl;
  display: inline-block;
  unicode-bidi: bidi-override;
  span.star {
    color: #777777;
    font-style: normal;
    font-weight: normal;
    font-size: rem(16px);
    display: inline-block;
    &:before {
      content: $i-star;
      font-family: "#{$icomoon-font-family}";
    }
    &.active {
      &,
      & ~ span.star {
        color: gold;
      }
    }

    &.interactive:hover {
      span.star {
        cursor: pointer;
        color: #777777;
      }
      &,
      & ~ span.star {
        color: gold;
      }
    }
  }
}

.mandatory:after {
  content: "*";
  color: #e00;
  line-height: 0;
  font: inherit;
}