body {
  overflow: hidden;
  &.onLoading {
    &:after,
    &:before {
      content: "";
      position: fixed;
    }
    &:before {
      z-index: 9999;
      pointer-events: all;
      background-color: rgba(#ccc, 0.8) !important;
      @extend .absFC;
    }
    &:after {
      top: 50%;
      left: 50%;
      z-index: 10000;
      @extend .loader;
      border-color: rgba(#000, 0.3);
      margin: rem(60px * 0.5) 0 0 rem(-60px * 0.5);
      border-top-color: map-get($theme, accent);
    }
    &[data-loader-message]:after {
      left: 0;
      right: 0;
      top: 50%;
      z-index: 10001;
      margin: 0 auto;
      display: block;
      background: #fff;
      position: absolute;
      text-align: center;
      margin-top: rem(25px);
      color: map-get($theme, color);
      content: attr(data-loader-message);
    }
  }
}

.footNote {
  font-size: 18px;
  margin-top: -5px;
  line-height: 135%;
  color: map-get($color, dark);
  @include media-breakpoint-down(map-get($global, device-switch)) {
    font-size: 14px;
    line-height: 160%;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 20px;
  }
}
.stick {
  @media (max-width: 1349px) and (min-width: 1200px) {
    margin-bottom: 2px !important;
  }
  @media only screen and (max-width: 1350px) {
    top: 0;
    z-index: 999;
    display: flex;
    position: sticky;
    align-items: center;
    background-color: map-get($theme, bg);
    padding: 0 0 0 var(--app-page-gutter-x, 24px);
    // margin-bottom: var(--app-page-gutter-x, 128px);
    width: calc(100% + (var(--app-page-gutter-x, 24px) * 2));
    transform: translateX(calc(var(--app-page-gutter-x, 24px) * -1));
  }
  // @include media-breakpoint-down(xl) {
  //   &:before {
  //     left: 0;
  //     top: 100%;
  //     content: "";
  //     width: 100%;
  //     height: 24px;
  //     display: block;
  //     position: absolute;
  //     @include linear-gradient(rgba(map-get($color, light), 1), rgba(map-get($color, light), 0));
  //   }
  // }
}

.btn-outline-light {
  &:focus {
    color: map-get($theme, bg);
    background: map-get($theme, text);
    border-color: map-get($theme, bg);
    &:hover {
      color: map-get($theme, text);
      background: map-get($theme, bg);
      border-color: map-get($theme, bg);
    }
  }
}

.img-sm {
  max-width: 94px;
  @include media-breakpoint-down(sm) {
    max-width: 78px;
  }
}

.figtitle {
  font-size: 26px;
  text-align: center;
  padding: 30px 0 10px;
  @include font($fontB);
}
.disclaimer {
  padding-top: 20px;
  padding-bottom: 55px;
  font-size: 18px;
  line-height: 135%;
  @include media-breakpoint-down(md) {
    padding-bottom: 28px;
  }
}
