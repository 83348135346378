.videoThumb {
  figure {
    margin-bottom: 0;
    position: relative;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 84px;
      height: 84px;
      margin: auto;
      display: flex;
      font-size: 45px;
      line-height: 74px;
      position: absolute;
      text-align: center;
      border-radius: 50%;
      align-items: center;
      @extend %animateFast;
      content: $i-play_arrow;
      background: transparent;
      justify-content: center;
      color: map-get($theme, bg);
      font-family: $icomoon-font-family;
      border: 4px solid map-get($theme, videoIcon);
    }

    img {
      width: 100%;
    }

    figcaption {
      left: 0;
      bottom: 0;
      width: 100%;
      font-size: 22px;
      text-align: left;
      line-height: 24px;
      padding: 10px 21px;
      position: absolute;
      @include font($fontB);
      color: map-get($color, light);
      background: rgba(map-get($color, dark), 0.1);
    }
  }
  .caption {
    font-size: 18px;
    line-height: 25px;
    margin: 20px 0 10px;
    @include font($fontSB);
    color: map-get($theme, colorL);
  }

  .description {
    font-size: 18px;
    line-height: 25px;
    @include font($font);
    color: map-get($color, dark);
  }

  &.rightBottom {
    figure {
      &:before {
        top: auto;
        left: auto;
        z-index: 1;
        margin: 30px;
      }
    }
  }

  &:hover,
  &:focus,
  &:active {
    figure {
      &:before {
        transform: scale(1.2);
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .tags,
    .caption,
    .description {
      padding: 0 35px;
    }
  }
}

.videoPlayer {
  width: 100%;
  overflow: hidden;
  position: relative;
  aspect-ratio: 16/9;
  border-radius: 8px;
  &:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 84px;
    height: 84px;
    margin: auto;
    display: flex;
    font-size: 45px;
    line-height: 74px;
    border-radius: 50%;
    position: absolute;
    text-align: center;
    align-items: center;
    @extend %animateFast;
    content: $i-play_arrow;
    background: transparent;
    justify-content: center;
    color: map-get($theme, bg);
    font-family: $icomoon-font-family;
    border: 4px solid map-get($theme, videoIcon);
  }

  video {
    height: 100%;
    cursor: pointer;
    max-width: 100%;
    border-radius: 8px;
    object-fit: contain;
    background: map-get($color, dark);
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      transform: scale(1.2);
    }
  }

  &.playing {
    &:before {
      opacity: 0;
      content: $i-pause;
    }
    &:hover,
    &:focus,
    &:active {
      &:before {
        // opacity: 1;
      }
    }
  }

  &.paused {
    &:before {
      content: $i-play_arrow;
    }
  }
}
