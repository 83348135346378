.maintainEyesight {
  margin: 6px 0 0;
  padding: 53px 0;
  background: map-get($theme, accent);
  @include media-breakpoint-down(md) {
    padding: 15px 0;
    margin-top: 36px;
    .h2 {
      font-size: 20px;
      margin-top: 12px;
      line-height: 160%;
    }
  }
  .navTrigger {
    @extend %animateFast;
    &:hover,
    &:focus,
    &:active {
      figure {
        &::before {
          transform: scale(1.2);
        }
      }
    }
  }
}

.popup {
  &.popupMaintainEyesight {
    .cardTabSwitch {
      padding-bottom: 110px;
      @include media-breakpoint-down(md) {
        padding-bottom: 20px;
      }
    }
  }
}
