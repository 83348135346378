.card {
  &.cardTabSwitch {
    margin-bottom: 0;
    box-shadow: none;
    margin: 0 auto;
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }
    .cardContent {
      padding: 0;
      .tab-content {
        .tab-pane {
          picture {
            img {
              border-radius: 8px;
            }
          }
        }
      }
    }
    .cardFooter {
      figure {
        &.ico {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          background: map-get($color, primary);
          box-shadow: 0px 0px 8px 1px rgba(map-get($color, dark), 0.1);
          margin: 0 auto 15px;
          border: 1.5px solid map-get($color, light);
          &:hover,
          &:focus,
          &:active {
            filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.1));
          }
          span {
            color: map-get($color, light);
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            @include font($fontB);
          }
          img {
            padding: 15px;
            @include media-breakpoint-down(md) {
              padding: 5px;
            }
          }
          .imgActive {
            // display: none;
            opacity: 0;
          }
          // &.active {
          //   span {
          //     color: map-get($theme, accent);
          //   }
          //   .imgActive {
          //     display: block;
          //   }
          //   .imgInactive {
          //     display: none;
          //   }
          // }
        }
        @include media-breakpoint-down(lg) {
          &.ico {
            width: 70px;
            height: 70px;
            span {
              font-size: 18px;
            }
          }
        }

        @include media-breakpoint-down(md) {
          &.ico {
            width: 48px;
            height: 48px;
            span {
              font-size: 14px;
            }
          }
        }
      }
      .nav-tabs {
        transform: translate(0px, -50px);
        border-bottom: 0;
        margin: 0 auto;
        &.sign {
          .nav-item {
            .nav-link {
              @include media-breakpoint-down(md) {
                // padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
                padding: var(--bs-nav-link-padding-y) calc(var(--bs-nav-link-padding-x) * 0.5) 0;
              }
            }
          }
        }
        .nav-item {
          max-width: 191px;
          // min-width: 110px;
          min-width: 87px;
          font-size: 18px;
          line-height: 135%;
          @include media-breakpoint-down(lg) {
            width: 200px;
          }
          .nav-link {
            border: none;
            padding-bottom: 0;
            color: map-get($color, dark);
            @include media-breakpoint-down(md) {
              // padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
              padding: var(--bs-nav-link-padding-y) calc(var(--bs-nav-link-padding-x) * 0.5);
            }
            &:hover,
            &:active,
            &:focus,
            &:focus-visible {
              // filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.25));
              color: map-get($color, dark);
            }
            &.active {
              background-color: transparent;
              border: none;
              .text {
                color: map-get($theme, accent);
              }
              .imgActive {
                // display: block;
                opacity: 1;
                @extend %animateFast;
              }
              .imgInactive {
                display: none;
              }
            }
          }
        }
        @include media-breakpoint-down(lg) {
          overflow-x: auto;
          flex-wrap: nowrap;
          overflow-y: hidden;
          transform: translate(0px, 10px);
          margin-bottom: 15px;
          .nav-item {
            font-size: 14px;
            padding-bottom: 40px;
          }
        }
        @include media-breakpoint-down(md) {
          margin-right: -35px;
          margin-left: -35px;
          padding-left: 15px;
          padding-right: 15px;
        }
      }
    }
  }
}
