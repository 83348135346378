.popup {
  &.popupPageViewer {
    &.popupHelpfulGa {
      .page {
        &.helpfulGA {
          // .pageHeader {
          // }
          .pageContent {
            .cardGroup {
              margin: 70px auto 0px;
              @include media-breakpoint-down(md) {
                margin: 45px 0 20px;
              }
              .card {
                max-width: 446px;
                box-shadow: none;
                padding: 38px 35px 35px 35px;
                background: map-get($theme, bgL);
                @include media-breakpoint-down(md) {
                  max-width: 100%;
                  margin-bottom: 24px;
                }
                .cardHeader {
                  padding: 0;
                  font-size: 26px;
                  @include font($fontB);
                  @media (max-width: 828px) and (min-width: 767px) {
                    min-height: 64px;
                  }
                  @include media-breakpoint-down(md) {
                    font-size: 24px;
                  }
                }
                .cardContent {
                  font-size: 22px;
                  padding: 30px 0 35px;
                  @include media-breakpoint-down(md) {
                    font-size: 20px;
                    padding: 15px 0 35px;
                  }
                }
                .cardFooter {
                  padding: 0;
                }
              }
            }
            .supportOrg {
              padding: 50px 0 116px;
              @include media-breakpoint-down(md) {
                padding: 10px 0 15px;
              }
              .supportHeader {
                max-width: 80%;
                margin: 0 auto;
                font-size: 26px;
                text-align: center;
                @include font($fontB);
                @include media-breakpoint-down(md) {
                  max-width: 100%;
                  font-size: 24px;
                  text-align: left;
                }
              }
              .cardOrg {
                padding: 30px 0 0;
                .card {
                  display: flex;
                  box-shadow: none;
                  margin-bottom: 0;
                  position: relative;
                  .cardHeader,
                  .cardFooter,
                  .cardContent {
                    position: static;
                  }

                  @include media-breakpoint-down(lg) {
                    margin-bottom: 40px;
                  }
                  @include media-breakpoint-down(md) {
                    align-items: center;
                    margin-bottom: 0;
                    &:after {
                      content: "";
                      height: 1px;
                      width: 165px;
                      position: absolute;
                      border-radius: 10px;
                      background: map-get($theme, brdrD);
                    }
                  }

                  .cardHeader {
                    min-height: 150px;

                    @include media-breakpoint-down(xl) {
                      justify-content: center;
                    }
                    @include media-breakpoint-down(md) {
                      padding-top: 35px;
                      padding-bottom: 0;
                    }

                    figure {
                      width: 100%;
                      display: flex;
                      margin: 0 auto;
                      max-width: 175px;
                      align-items: center;
                      picture {
                        padding: 1px 0;
                      }
                      @include media-breakpoint-down(lg) {
                        margin: 0 auto;
                        min-height: 85px;
                        max-width: 220px;
                        justify-content: center;
                        picture {
                          padding: unset;
                        }
                      }
                    }
                    &.imgBig {
                      max-width: 200px;
                      @include media-breakpoint-down(md) {
                        padding-top: 0px;
                        margin-top: -14px;
                      }
                    }
                    &.imgsm {
                      @include media-breakpoint-down(sm) {
                        max-width: 220px;
                      }
                    }
                  }
                  .cardFooter {
                    margin-top: auto;
                    @include media-breakpoint-down(md) {
                      margin-bottom: 35px;
                    }
                    .btn {
                      margin: 0 auto;
                      text-align: center;

                      & > span + .i {
                        margin-left: 5px;
                      }
                      &.btn-primary {
                        &:focus {
                          border-color: transparent;
                        }
                        &:hover {
                          &,
                          &:focus {
                            border-color: map-get($theme, text);
                          }
                        }
                      }
                    }
                  }
                }

                @include media-breakpoint-down(md) {
                  & > div {
                    &:first-child {
                      .card {
                        &:after {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
