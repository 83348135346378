.con_with_doc {
  position: relative;
  padding-top: 40px;
  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }
  img {
    border-radius: 8px;
  }
}
.askDoc {
  position: absolute;
  bottom: 35px;
  max-width: 441px;
  right: 50px;

  background: rgba(map-get($theme, bg), 0.95);
  padding: 45px 35px;
  border-radius: 8px;

  @include media-breakpoint-down(map-get($global, device-switch)) {
    position: static;
    max-width: 100%;
    padding: 25px 0 0;
  }
  .askDoc-subHead {
    font-size: 26px;
    @include font($fontB);
    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }
  .askList {
    font-size: 22px;
    margin-bottom: 0;
    padding-left: 10px;
    @include media-breakpoint-down(md) {
      font-size: 20px;
      padding-left: 1rem;
    }
    & > li {
      padding-top: 25px;
      list-style: none;

      position: relative;
      padding-left: 20px;
      &:before {
        left: 5px;
        top: 1.7em;
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        position: absolute;
        border-radius: 50%;
        background: map-get($theme, text);
      }
    }
  }
}
