.capsuleTabs {
  @include media-breakpoint-down(lg) {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .nav-tabs {
    position: relative;
    margin: 5px auto 30px;
    border-radius: 74px;
    max-width: max-content;
    border: 2px solid map-get($theme, brdrL);
    box-shadow: 0px 0px 12px 2px rgba(map-get($color, primary), 0.1);
    &.quicktab {
      .nav-item {
        .nav-link {
          @include media-breakpoint-down(sm) {
            font-size: 16px;
          }
          @media only screen and (max-width: 420px) {
            font-size: 16px;
            padding: 0.5rem;
          }
        }
      }
    }
    .nav-item {
      z-index: 1;
      padding: 7px;

      .nav-link {
        border: none;
        //   width: 169px;
        // width: 227px;
        font-size: 22px;
        border-radius: 10em;
        @extend %animateFast;
        @include font($fontB);
        &:hover,
        &:active,
        &:focus {
          color: map-get($cont, accent);
          background: rgba(map-get($color, accent), 0.16);
        }
        &.active {
          // color: inherit;
          background: transparent;
          color: map-get($cont, primary);
          &:hover,
          &:active,
          &:focus {
            background: transparent;
          }
        }
      }
    }
    .inkBar {
      opacity: 0;
      z-index: 0;
      position: absolute;
      @extend %animateFast;
      pointer-events: none;
      // transform: translateY(7px);
      background: map-get($color, primary);
      height: var(--app-capsule-tab-height, 100%);
      border-radius: var(--app-capsule-radius, 10em);
      &._ {
        opacity: 1;
      }
    }
  }

  .tab-content {
    margin-top: 24px;
    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
    figure {
      figcaption {
        font-size: 18px;
        margin-top: 12px;
        text-align: center;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .scrollX {
      width: 100vw;
      display: flex;
      padding: 24px 24px 24px;
      // @include scrollbar(8px, rgba(map-get($theme, scroll), 0), rgba(map-get($theme, scroll), 0));
      // &.tabOuter {
      //   margin-right: 24px;
      // }
    }
    .nav-tabs {
      margin: 0;
      width: 100%;
      display: block;
      width: min-content;
      white-space: nowrap;
      max-width: max-content;
      &.quicktab {
        @media (max-width: 420px) and (min-width: 480px) {
          width: 100%;
        }
      }
      .nav-item {
        margin: 0;
        position: relative;
        padding: 7px 7px 7px 0;
        display: inline-block;
        .nav-link {
          position: relative;
        }
        &:first-child {
          margin-left: 7px;
          margin-right: 7px;
        }
      }
      // .inkBar {
      //   transform: translateY(7px);
      // }
    }
  }
  // @include media-breakpoint-down(md) {
  //   .nav-tabs {
  //     max-width: 100%;
  //     .nav-item {
  //       .nav-link {
  //         font-size: 16px;
  //       }
  //     }
  //   }
  // }
  @include media-breakpoint-down(sm) {
    .capsuleTabs {
      .scrollX {
        width: 100vw;
        padding: 0 24px 24px;
        &.tabOuter {
          margin-right: 40px;
        }
      }
    }
  }
  @media only screen and (max-width: 420px) {
    .nav-tabs {
      .nav-item {
        .nav-link {
          font-size: 12px;
        }
      }
    }
  }
}
