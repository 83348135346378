.popup.popupPageViewer {
  .page.hearFrom {
    .pageContent {
      .details {
        padding-bottom: 0;
      }
    }
    .center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .hearFromGA {
      padding-bottom: 110px;
      @include media-breakpoint-down(md) {
        padding-bottom: 20px;
      }

      .ratio {
        background-color: map-get($theme, color);
      }
    }
  }
}
