$baseFontSize: 20px;
$fontFamily: "Karla", sans-serif;
$scFontFamily: "Karla", sans-serif;

// COMMON FONT CHART
// -----------------
$fontUL: 200; // 'fontName ultralight', sans-serif;
$fontT: 200; // 'fontName thin', sans-serif;
$fontL: 300; // 'fontName light', sans-serif;
$font: 400; // 'fontName regular', sans-serif;
$fontM: 500; // 'fontName medium', sans-serif;
$fontSB: 600; // 'fontName semibold', sans-serif;
$fontB: 700; // 'fontName bold', sans-serif;
$fontH: 800; // 'fontName heavy', sans-serif;
$fontBK: 800; // 'fontName black', sans-serif;

$global: (
  // GLOBAL vars
  device-switch: xl,
  border-radius: 8px,
  border-radius-sm: 4px,
  border-radius-lg: 8px,
  titleFont: $scFontFamily
);

$matBorderRadius: map-get($global, border-radius);

$theme: (
  // THEME vars
  font: $font,
  fontL: $fontL,
  fontT: $fontT,
  fontM: $fontM,
  fontB: $fontB,
  fontH: $fontH,
  fontUL: $fontUL,
  fontSB: $fontSB,
  fontBK: $fontBK,
  fontFamily: $fontFamily,
  scFontFamily: $scFontFamily,
  baseFontSize: $baseFontSize,
  bg: #fff,
  text: #000,
  link: #000,
  write: #fff,
  color: #000,
  scroll: #000,
  bgL: #f8f8f8,
  bgI: #f2f2f2,
  brdr: #d5d5d7,
  brdrD: #c9c8c9,
  brdrL: #e5e5e5,
  matBg: #edeef0,
  colorL: #626262,
  accent: #66ccff,
  marker: #66ccff,
  selection: #66ccff,
  header: (
    bg: #fff,
    link: #000,
    text: #000,
  ),
  footer: (
    bg: #f8f8f8,
    link: #424242,
    text: #424242,
  ),
  types: (
    dark: #000,
    light: #fff,
    primary: #000,
    warning: #f30,
    info: #006ed0,
    accent: #66ccff,
    danger: #a10036,
    success: #00997a,
    secondary: #66ccff,
  ),
  contrast: (
    dark: #fff,
    light: #000,
    primary: #fff,
    warning: #fff,
    info: #fff,
    accent: #000,
    danger: #fff,
    success: #fff,
    secondary: #000,
  )
);

$color: map-get($theme, types);
$cont: map-get($theme, contrast);
