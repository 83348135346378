.collapseContent {
  text-align: left;
  margin-top: 25px;
  border-radius: 8px;
  // margin-top: 10px;
  border: 1px solid map-get($theme, brdrD);
  @include media-breakpoint-down(md) {
    margin-top: 15px;
  }
  .btn {
    &.btnClick {
      width: 100%;
      padding: 10px 24px;
      font-size: 22px;
      min-height: 70px;
      position: relative;
      @include font($fontB);
      &:focus,
      &:active {
        border-color: transparent;
      }
      @include media-breakpoint-down(md) {
        font-size: 20px;
        min-height: 50px;
        padding: 10px 12px;
        //padding: 10px;
      }
      &::before {
        position: absolute;
        height: 1px;
        width: calc(100% - 48px);
        left: 24px;
        bottom: 0;
        content: "";
        background-color: map-get($theme, brdrD);
        @include media-breakpoint-down(md) {
          width: calc(100% - 24px);
          left: unset;
        }
      }
      .arrow {
        position: absolute;
        right: 24px;
        height: 36px;
        width: 36px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(md) {
          right: 12px;
        }
        i {
          transition: transform ease 0.4s;
          font-size: 30px;
        }
      }
      &.collapsed {
        &::before {
          display: none;
        }
        .arrow {
          i {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
  .collapseDtls {
    padding: 24px;
    position: relative;
    .content {
      max-height: 300px;
      overflow-x: hidden;
      padding-right: 24px;
    }
    &:after {
      left: 0;
      content: "";
      // width: 100%;
      width: calc(100% - 48px);
      bottom: 24px;
      height: 24px;
      position: absolute;
      border-radius: 0 0 8px 8px;
      @include linear-gradient(rgba(map-get($color, light), 0), rgba(map-get($color, light), 1));
    }
    @include media-breakpoint-down(md) {
      padding: 12px;
      font-size: 20px;
      .content {
        padding-right: 5px;
        margin-bottom: 10px;
      }
      &:after {
        bottom: 12px;
        width: calc(100% - 17px);
      }
    }
  }
}
