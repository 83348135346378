.popup {
  &.popupPageViewer {
    &.popupTermsToKnow {
      .page {
        &.termsKnow {
          .pageHeader {
            @include media-breakpoint-down(md) {
              margin-bottom: 15px;
            }
          }
          .pageContent {
            .cardList {
              .cardWrap {
                @include notaList;
                padding: 10px 0 97px;
                @include media-breakpoint-down(md) {
                  padding: 10px 0 25px;
                }
                .card {
                  box-shadow: none;
                  display: flex;
                  flex-direction: row;
                  padding: 7px 0;
                  font-size: 22px;
                  @include media-breakpoint-down(md) {
                    flex-direction: column;
                    font-size: 20px;
                    padding: 4px 0;
                  }
                  .cardHeader {
                    width: 15%;
                    padding: 0;
                    @include font($fontB);
                    @include media-breakpoint-down(lg) {
                      width: 20%;
                    }
                    @include media-breakpoint-down(md) {
                      width: 100%;
                      padding: var(--nitro-gutter-x) 0;
                    }
                  }
                  .cardContent {
                    @include media-breakpoint-down(md) {
                      width: 100%;
                      padding: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
