$footer: map-get($theme, footer);

footer.footer {
  padding: 40px 0;
  // font-size: rem(14px);
  // line-height: em(18px, 14px);
  color: map-get($footer, text);
  background: map-get($footer, bg);
  margin: 0 auto;
  text-align: center;
  //max-height: 216px;
  @include media-breakpoint-down(md) {
    // max-height: 232px;
  }
  .footerLogo {
    color: map-get($footer, link);
    width: 100%;
    display: block;
    max-width: 100px;
    margin: 0 auto;
  }
  img {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 25px;
    @include media-breakpoint-down(sm) {
      max-width: 90px;
    }
  }
  .footerLinks {
    color: map-get($footer, text);
    background: map-get($footer, bg);
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    .footerItems {
      text-decoration: underline;
      text-align: center;
      justify-content: center;
      padding: 0 20px;
      display: flex;
      font-size: 18px;
      margin: 0;
      border-right: 1px solid map-get($footer, text);
      &:last-child {
        border: none;
      }
    }
  }
  .copyright {
    font-size: 14px;
    text-align: center;
    @include media-breakpoint-down(md) {
      padding: 0 30px;
      line-height: 20px;
    }
  }
}
