.banner {
  width: 100vw;
  // min-height: 100vh;
  // padding-top: var(--app-header-height, 100);
  min-height: auto;
  &.four {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px 0 45px;
    @include media-breakpoint-down(md) {
      margin: 70px 0 85px;
    }
    .textWrap {
      .title {
        width: 100%;
        min-height: 100%;
        font-size: rem(70px);
        padding-bottom: 20px;
        @include media-breakpoint-down(md) {
          font-size: 48px;
          padding-bottom: 40px;
        }
      }
      & > .h4,
      h4 {
        font-size: 36px;
        padding-bottom: 10px;
        @include font($fontB);
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }
      .details-404 {
        font-size: 22px;
        padding-bottom: 60px;
        @include media-breakpoint-down(md) {
          font-size: 20px;
          padding-bottom: 40px;
        }
      }
    }
  }
}
